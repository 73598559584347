import React from "react"
import { graphql, StaticQuery} from "gatsby"

// Pages
import "../utils/normalize.css"
import "../utils/css/v2.css"

// Partials
import { PageContentBuilder } from "../components/partials/page-content-builder";

const CareersPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return <PageContentBuilder siteTitle={siteTitle} headerActive={`Careers`} location={location} title={`Careers`} keywords={[]} subsection={'careers'} api={true}/>
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <CareersPage location={props.location} data={data} {...props} />
    )}
  />
)
